import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Contacts from "./pages/Contacts";
import Profile from "./pages/Profile";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./auth/ProtectedRoute";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";
import Booking from "./pages/Booking";
import Wardrobe from "./pages/Wardrobe";
import Orders from "./pages/Orders";
import Articles from "./pages/Articles";
import ComingSoon from "./pages/ComingSoon";
import Hotels from "./pages/Hotels";

const App = () => (
  <Router>
    <Auth0ProviderWithHistory>
      <Navbar />
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        {/* <Route path="/logout" element={<Home />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/profile" element={<ProtectedRoute />}>
          <Route path="" element={<Profile />} />
        </Route>
        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route path="" element={<Dashboard />} />
        </Route>
        <Route path="/booking" element={<ProtectedRoute />}>
          <Route path="" element={<Booking />} />
        </Route>
        <Route path="/wardrobe" element={<ProtectedRoute />}>
          <Route path="" element={<Wardrobe />} />
        </Route>
        <Route path="/orders" element={<ProtectedRoute />}>
          <Route path="" element={<Orders />} />
        </Route>
        <Route path="/articles" element={<ProtectedRoute />}>
          <Route path="" element={<Articles />} />
        </Route>
        <Route path="/hotels" element={<ProtectedRoute />}>
          <Route path="" element={<Hotels />} />
        </Route> */}
      </Routes>
    </Auth0ProviderWithHistory>
  </Router>
);

export default App;
