import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { config } from "../config";
import { RiLogoutBoxRFill } from "react-icons/ri";

const Navbar = () => {

  // const location = useLocation();

  const { user, loginWithRedirect, logout, isAuthenticated, isLoading } =
    useAuth0();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };
  
  if (isLoading) {
    return (
      <>
        <div className={config.classNames.topBoard.bg}>Loading ...</div>
      </>
    );
  }
  
  return (
    <nav className={config.classNames.topBoard.bg}>
      {isAuthenticated && user ? (
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <img
            className="fill-current h-8 w-8 mr-2 rounded-full"
            width="54"
            height="54"
            src={user.picture}
            alt={user.email}
          />
          <p>
            <span className="font-semibold text-xl tracking-tight">
              {user.name}
            </span>
            <br />
            <span className="font-condensed text-m tracking-tight">
              Pristine Luxury
              <button
                title={t("logout")}
                onClick={() =>
                  logout({ logoutParams: { returnTo: config.urls.logout } })
                }
                className="float-right mt-2"
              >
                <RiLogoutBoxRFill />
              </button>
              
            </span>
          </p>
        </div>
      ) : (
        <div className="flex items-center flex-shrink-0 text-white mr-6">
          <div className="fill-current h-8 w-8 mr-2">
            <img src="/logo.svg" alt="Pristine" width="54" height="54" />
          </div>
          <span className="font-semibold text-xl tracking-tight">
            Pristine Luxury
          </span>
        </div>
      )}
      <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
        <div className="text-sm font-semibold lg:flex-grow">
          {/* <Link to="/" className="mr-4 mt-2">
            {t("home")}
          </Link> */}
          {isAuthenticated ? (
            <>
             <Link to="/articles" className="mr-4 mt-2">
                {t("articles")}
              </Link>
              <Link to="/orders" className="mr-4 mt-2">
                {t("orders")}
              </Link>
              <Link to="/booking" className="mr-4 mt-2">
                {t("booking")}
              </Link>
              <Link to="/wardrobe" className="mr-4 mt-2">
                {t("wardrobe")}
              </Link>
              <Link to="/profile" className="mr-4 mt-2">
                {t("profile")}
              </Link>
              <Link to="/hotels" className="mr-4 mt-2">
                {t("hotels")}
              </Link>
              {/* <Link to="/dashboard" className="mr-4 mt-2">
                {t("dashboard")}
              </Link> */}
            </>
          ) : (
            <></>
            // <button onClick={() => loginWithRedirect()} className="mr-4 mt-2">
            //   {t("login")}
            // </button>
          )}
          {/* <Link to="/contacts" className="mr-4 mt-2">
            {t("contacts")}
          </Link> */}
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue={i18n.language}
            className={config.classNames.topBoard.langBg}
          >
            <option value="en">English</option>
            <option value="bg">Български</option>
            <option value="de">Deutsch</option>
            <option value="it">Italiano</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
          </select>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
