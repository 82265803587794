export const config = {
  urls: {
    logout: 'http://localhost:3000/logout',
  },
  classNames: {
    topBoard: {
      bg: "flex items-center justify-between flex-wrap bg-stone-900 p-4 text-orange-100",
      langBg:
        "bg-stone-900 text-orange-100 border border-white p-2 rounded float-right",
      avatar: {
        div: "flex -space-x-1 overflow-hidden",
        img: "mr-4 inline-block h-7 w-7 rounded-full ring-2 ring-white",
      },
    },
  },
};
