import React from "react";
import { useTranslation } from "react-i18next";

const ComingSoon = () => {
  const { t } = useTranslation();

  return (
    <div className="min-h-screen bg-stone-900 flex flex-col items-center justify-center relative px-4">
    {/* <div className="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-30 bg-[url('https://images.unsplash.com/photo-1549294413-26f195200c16?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')]"> */}
    <div className="absolute top-0 left-0 w-full h-full bg-cover bg-center opacity-30 bg-custom-bg bg-[url('https://pristineluxury.world/bg.jpg')]">

    </div>
    <h1 className="text-5xl md:text-7xl text-white font-bold mb-8 z-10">{t('commingSoon')}</h1>
    <p className="text-white text-xl md:text-2xl">
        {t('commingSoonText')}
    </p>
</div>
  );
};

export default ComingSoon;
